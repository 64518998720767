import { Switch as HeadlessSwitch } from '@headlessui/react';

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  srText?: string;
}

export function Switch({ checked, onChange, srText }: SwitchProps) {
  return (
    <HeadlessSwitch
      checked={checked}
      onChange={onChange}
      className="cursor-pointer group inline-flex h-6 w-11 items-center rounded-full bg-carbon-200 data-[checked]:bg-carbon-800 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
    >
      <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
      {srText && <span className="sr-only">{srText}</span>}
    </HeadlessSwitch>
  );
}
