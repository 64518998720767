import { Switch } from '@carbonfact/ui-components/src/Form/Switch';
import Icon from '@carbonfact/ui-components/src/Icon';
import Loader from '@carbonfact/ui-components/src/Loader';
import { useCallback, useEffect, useState } from 'react';
import { mutate } from 'swr';
import NavigationItem from './Navigation/NavigationItem';

export const LIVE_PREVIEW_SESSION_STORAGE_KEY = 'previewingLive';

export function LiveStableSwitcher() {
  const [isPreviewingLive, setIsPreviewingLive] = useState(false);
  const [loading, setLoading] = useState(false);

  const refreshSessionStorageValue = useCallback(() => {
    setIsPreviewingLive(
      Boolean(window.sessionStorage.getItem(LIVE_PREVIEW_SESSION_STORAGE_KEY)),
    );
  }, []);

  const toggleVersionPreview = useCallback(async () => {
    setLoading(true);
    try {
      if (isPreviewingLive) {
        window.sessionStorage.removeItem(LIVE_PREVIEW_SESSION_STORAGE_KEY);
      } else {
        window.sessionStorage.setItem(LIVE_PREVIEW_SESSION_STORAGE_KEY, 'true');
      }

      // Invalidate all queries
      await mutate(() => true, undefined, {
        revalidate: true,
      });
      refreshSessionStorageValue();
    } finally {
      setLoading(false);
    }
  }, [isPreviewingLive, refreshSessionStorageValue]);

  useEffect(() => {
    refreshSessionStorageValue();

    window.addEventListener('focus', refreshSessionStorageValue);
    return () => {
      window.removeEventListener('focus', refreshSessionStorageValue);
    };
  }, [refreshSessionStorageValue]);

  return (
    <NavigationItem
      active={false}
      onClick={() => {
        if (loading) return;
        toggleVersionPreview();
      }}
    >
      <Icon
        icon={{
          source: 'hero',
          type: 'line',
          name: 'EyeIcon',
        }}
        width={20}
        height={20}
      />
      {'Stable'}

      {loading ? (
        <div className="h-[18px]">
          <Loader
            className="scale-75 relative bottom-[8px] w-[44px]!"
            type="inline"
          />
        </div>
      ) : (
        <div className="h-[18px] relative bottom-[3px]">
          <Switch
            checked={isPreviewingLive}
            onChange={() => {
              if (loading) return;
              toggleVersionPreview();
            }}
          />
        </div>
      )}

      {'Live'}
    </NavigationItem>
  );
}
