import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { Analytics } from 'app/lib/analytics';
import type React from 'react';
import { type Fetcher, SWRConfig } from 'swr';

import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { LIVE_PREVIEW_SESSION_STORAGE_KEY } from 'app/components/LiveStableSwitcher';
import { useCallback } from 'react';
import { API_BASE_URL } from '../../configuration/constants';
import { useCurrentUser } from './AuthContext';

export class FetcherError extends Error {
  readonly rawUrl: string;

  readonly host: string;
  readonly method: string;
  readonly path: string;
  readonly search?: string;

  readonly status: number;
  readonly body: unknown;

  static reRaise(error: FetcherError) {
    return new FetcherError(
      error.method,
      error.status,
      error.rawUrl,
      error.body,
    );
  }

  constructor(method: string, status: number, rawUrl: string, body: unknown) {
    const url = new URL(rawUrl);
    const msg = `API Error fetching ${method} ${url.pathname}, ${status} : ${(body as { message?: string })?.message ?? JSON.stringify(body)}`;

    super(msg);

    this.rawUrl = rawUrl;
    this.host = url.host;
    this.method = method;
    this.path = url.pathname;
    this.search = url.search;
    this.status = status;
    this.body = body;
    this.message = msg;
  }
}

export const useFetchWithAuth = (options?: { autoParseJson?: boolean }) => {
  const { getAccessTokenSilently } = useAuth0();

  const autoParseJson = options?.autoParseJson ?? true;

  return useCallback(
    async <R = unknown>(
      path: URL | RequestInfo,
      req?: RequestInit & { omitContentType?: boolean },
    ): Promise<typeof autoParseJson extends false ? Response : R> => {
      const url = `${API_BASE_URL}${path}`;

      let accessToken: string | undefined;
      try {
        // Try and get an access token for the API, using a refresh token if needed
        // We've set useRefreshTokensFallback to true, so if the refresh token expired too,
        // users will be prompted to re-authenticate.
        accessToken = await getAccessTokenSilently();
      } catch (e) {
        console.warn('Error getting API access token', e);
        // see handler below.
      }

      if (!accessToken) {
        // Couldn't get API access token for any reason, throw a FetcherError that our SWR handler will pick up automatically to trigger a clean re-auth.
        throw new FetcherError(
          req?.method ?? 'GET',
          401,
          url,
          'Failed to get API access token',
        );
      }

      const shouldSendLivePreviewHeader = window.sessionStorage.getItem(
        LIVE_PREVIEW_SESSION_STORAGE_KEY,
      );

      const res = await fetch(url, {
        ...req,
        headers: {
          Accept: 'application/json',
          ...(req?.omitContentType
            ? {}
            : { 'Content-Type': 'application/json' }),
          Authorization: `Bearer ${accessToken}`,
          ...(shouldSendLivePreviewHeader
            ? {
                'X-Carbonfact-Live-Preview': 'true',
              }
            : {}),
          ...req?.headers,
        },
      });

      if (!res.ok) {
        throw new FetcherError(
          req?.method ?? 'GET',
          res.status,
          url,
          await res.json(),
        );
      }

      if (autoParseJson === false) {
        // @ts-expect-error Response and R mismatch - handled by function signature
        return res;
      }

      const responseContentType = res.headers.get('Content-Type');
      if (responseContentType?.includes('application/json')) {
        return res.json() as R;
      }

      return res.text() as R;
    },
    [getAccessTokenSilently, autoParseJson],
  );
};

// Open a connection to receive Server-Sent Events from endpoints on the API
export const useFetchEventSourceWithAuth = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  return useCallback(
    async <M = unknown>(
      path: string,
      req: RequestInit,
      {
        onmessage,
      }: {
        onmessage?: (event: M) => void | Promise<void>;
      },
    ) => {
      let accessToken: string | undefined;
      try {
        accessToken = await getAccessTokenSilently().catch();
      } catch {
        // Couldn't get access token for any reason, force user to re-login.
        await logout();
      }

      if (!accessToken) return;

      const url = `${API_BASE_URL}${path}`;

      const source = await fetchEventSource(url, {
        ...req,
        headers: {
          'Content-Type': 'application/json',
          Accept: EventStreamContentType,
          Authorization: `Bearer ${accessToken}`,
        },
        async onopen(response) {
          if (
            response.ok &&
            response.headers.get('content-type') === EventStreamContentType
          ) {
            console.debug('Fetch event source: connection established');
            return; // everything's good
          }
          if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
          ) {
            // client-side errors are usually non-retriable:
            throw new FetcherError(
              'GET',
              response.status,
              url,
              await response.json(),
            );
          }
          throw new Error('retry');
        },
        onmessage(event) {
          let data = event.data;
          if (!data) {
            // likely blank line to keep connection open, skip it
            return;
          }
          try {
            data = JSON.parse(data);
          } catch (e) {
            // assume not JSON
          }
          onmessage?.(data as M);
        },
        onclose() {
          // If the server closes the connection unexpectedly, retry
          throw new Error('retry');
        },
        onerror(err) {
          if ((err as Error).message === 'retry') {
            // do nothing to automatically retry. You can also
            // return a specific retry interval here.
            return;
          }

          if (
            (err instanceof TypeError &&
              (err.message === 'network error' ||
                err.message === 'Failed to fetch')) ||
            err.message?.includes('BodyStreamBuffer was aborted')
          ) {
            // Automatically retry to connect on network errors, after 2 seconds
            console.warn(
              'Fetch event source: lost connection to server. Retrying in 2 seconds...',
            );
            return 2000;
          }

          throw err; // rethrow to stop the operation
        },
      });

      return source;
    },
    [getAccessTokenSilently, logout],
  );
};

export const SWRContext = (props: { children?: React.ReactNode }) => {
  const fetchWithAuth = useFetchWithAuth();
  const { loginWithRedirect } = useAuth0();

  const currentUser = useCurrentUser();
  const fetcher: Fetcher = (resource: string) => {
    return fetchWithAuth(resource, {
      method: 'GET',
    });
  };

  return (
    <SWRConfig
      value={{
        fetcher,
        onError: (error: FetcherError | TypeError | Error) => {
          if (
            error instanceof TypeError &&
            (error.message === 'Failed to fetch' ||
              error.message ===
                'NetworkError when attempting to fetch resource.')
          ) {
            // Network error, SWR will auto-retry, and we monitor API downtime separately
            return;
          }

          if (error instanceof FetcherError) {
            if (error.path.startsWith('/ai-chat')) {
              // TODO: fix the ai chat prototype and remove this
              return;
            }

            // Handle missing token and authorization errors
            if (error.status === 401 || error.status === 403) {
              // Unauthorized or Forbidden, force user to re-login
              void loginWithRedirect({
                // Try to get back to the current page after re-auth
                appState: {
                  returnTo: `${window.location.pathname}${window.location.search}`,
                },
                authorizationParams: {
                  prompt: 'login',
                },
                openUrl: (url) => {
                  // Force actual browser navigation to trigger a next.js refetch as we are not
                  // a "proper" client-side SPA, so just chaging window.location won't load up
                  // pages properly
                  window.open(url, '_self');
                },
              });
              return;
            }

            // API fetching errors will always go through this path and have the same stack trace.
            // Make sure to group them by what the API error actually is, rather than the stack trace.
            Sentry.withScope((scope) => {
              // Docs:
              // https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting
              scope.setFingerprint([
                error.method,
                error.path,
                String(error.status),
              ]);

              const isCarbonfactUser =
                currentUser?.email.endsWith('@carbonfact.com');
              const isCarbonfact404 = error.status === 404 && isCarbonfactUser;

              // We don't want to log 404s for carbonfact users, as they happen a lot when switching accounts
              if (isCarbonfact404) return;

              Sentry.captureException(error, {
                data: {
                  host: error.host,
                  method: error.method,
                  path: error.path,
                  search: error.search,
                  status: error.status,
                  body: error.body,
                },
              });
            });

            // 404s should be handled by the component
            // Track them on posthog for volume tracking too
            if (error.status === 404) {
              Analytics.capture('404_not_found', {
                url: error.path,
              });
              return;
            }

            return;
          }

          // Unhandled / unknown errors
          console.error(error);
          Sentry.captureException(error);
        },
      }}
    >
      {props.children}
    </SWRConfig>
  );
};
