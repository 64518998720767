import { getRequestConfig } from 'next-intl/server';
import { notFound } from 'next/navigation';

// Can be imported from a shared config
export const SUPPORTED_LOCALES = ['en', 'fr'];

export default getRequestConfig(async ({ requestLocale }) => {
  const locale = await requestLocale;

  // Validate that the incoming `locale` parameter is valid
  if (!locale || !SUPPORTED_LOCALES.includes(locale)) notFound();

  //
  // // Add continent codes too
  // WORLD: 'World',
  // AF: 'Africa', // Overrides Afghanistan
  // NA: 'North America', // Overrides Namibia
  // OC: 'Oceania',
  // AN: 'Antarctica',
  // AS: 'Asia', // Overrides American Samoa
  // EU: 'Europe',
  // SA: 'South America', // Overrides Saudi Arabia
  //
  const messages = {
    ...(
      (await import(`../messages/${locale}.json`)) as {
        // biome-ignore lint/suspicious/noExplicitAny: we can have a mix of string and objects, but the keys we merge are always objects
        default: Record<string, any>;
      }
    ).default,
  };
  for (const [key, value] of Object.entries(
    (
      (await import(
        `@carbonfact/shared/src/generated/messages/couture_${locale}.json`
      )) as {
        default: Record<string, Record<string, string>>;
      }
    ).default,
  )) {
    messages[key] = { ...(messages[key] ?? {}), ...value };
  }
  return {
    locale,
    messages,
  };
});
